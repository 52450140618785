import * as React from "react";
import Layout from "../components/layout";
import { graphql, HeadFC } from "gatsby";
import { TbWriting } from "react-icons/tb";
import { PortableText } from "@portabletext/react";
import { GatsbyImage } from "gatsby-plugin-image";
import PostBlock from "../components/postblock";

const Author = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const Postlist = data.allSanityPost.nodes;
  const Author = data.sanityAuthor;
  let path;
  return (
    <main>
      <Layout></Layout>
      <article className="h-screen flex flex-col justify-center items-center gap-4 rounded-xl p-10 transition-all duration-500 group-hover:bg-richblack">
        <GatsbyImage
          image={Author.image.asset.gatsbyImageData}
          alt={Author.image.alt}
          className="w-40 rounded-full"
        ></GatsbyImage>
        <h1 className="text-4xl text-richblack font-extrabold transition-all duration-500 group-hover:text-platinum underline">
          {Author.name}
        </h1>
        <h2 className="text-shadowblue transition-all duration-500 group-hover:text-bdazzledblue">
          {Author.position}
        </h2>
        <span
          className="[&>p]:px-36 [&>p]:text-justify [&>p]:text-richblack [&>p]:font-medium [&>p]:tracking-wide [&>p]:text-2xl
              [&>p]:transition-all [&>p]:duration-500 [&>p]:group-hover:text-platinum"
        >
          <PortableText value={Author._rawBio}></PortableText>
        </span>
      </article>
      <span className="h-screen flex flex-col justify-center items-center gap-8 px-12">
        <h1 className="text-5xl text-center text-richblack font-extrabold underline flex flex-row gap-2 items-center">
          <TbWriting></TbWriting>My Work with Dragonfly Post
        </h1>
        <ul className="flex gap-4 flex-wrap justify-center items-center py-4">
          {Postlist.map((node: any) => {
            path = `/posts/${node.slug.current}`;
            return (
              <li className="flex-grow basis-[50rem]">
                <PostBlock node={node}></PostBlock>
              </li>
            );
          })}
        </ul>
      </span>
    </main>
  );
};

export const Head: HeadFC = ({ pageContext }: { pageContext: any }) => (
  <title>{pageContext.currentAuthor}</title>
);

export const query = graphql`
  query ($currentAuthor: String, $id: String) {
    allSanityPost(
      filter: {
        authors: { elemMatch: { author: { name: { eq: $currentAuthor } } } }
      }
    ) {
      nodes {
        authors {
          author {
            slug {
              current
            }
            name
          }
        }
        categories {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
        title
        publishedAt(formatString: "DD.MM.YYYY HH:mm")
        mainImage {
          asset {
            gatsbyImageData
          }
          alt
        }
        _rawExcerpt
      }
    }
    sanityAuthor(_id: { eq: $id }) {
      name
      position
      _rawBio
      image {
        asset {
          gatsbyImageData
        }
        alt
      }
    }
  }
`;

export default Author;
